.h-24::-webkit-scrollbar {
  width: 6px;     
  border-radius: 3px;
}

.h-24::-webkit-scrollbar-thumb {
  width: 6px;
  border-radius: 3px;
  background-color: #f4f4f5;
}
